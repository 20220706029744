import {Link} from 'gatsby'
import Appshell from '../../components/Appshell'
import Background from '../../uikit/Background'
import mobileBackground from '../../assets/mobile-background.jpg'
import desktopBackground from '../../assets/desktop-background.jpg'
import Text from '../../uikit/Text'
import Button from '../../uikit/Button'
import Flex from '../../uikit/Flex'


const text = {
  title: 'predplatné',
  description: `Nech sa páči, tu si môžete zvoliť vaše knižné predplatné! Na výber máte niekoľko možností. Môžete
  ho taktiež kedykoľvek zrušiť, alebo jednoducho obnoviť. Navyše - platiť môžete až po doručení kníh. Predplatné
  začína platiť vždy od nasledujúceho kalendárneho mesiaca (kvôli času na naskladnenie kníh), pričom knižnú
  zásielku môžete očakávať približne v polovici mesiaca. *<br/><br/>
  (príklad: ak žiadosť o predplatné odošlete 1.novembra, prvý knižný výber vám príde v polovici decembra. Výber
  sa aktualizuje vždy 10. deň v mesiaci)<br/>
  * príplatok pri objednávke do ČR: +3€<br/><br/>`,
}

const Subscription = () => {
  return (
    <Appshell>
      <Background desktopBackground={desktopBackground} mobileBackground={mobileBackground}>
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">
            {text.title}
          </Text>
          <p>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{__html: text.description}} />
          </p>
          <Flex column crossAxis="center">
            <Button component={Link} isLink to="/subscription/once">jednorazový nákup</Button>
            <Button component={Link} isLink to="/subscription/quaterly">3 mesiace (4/70)</Button>
            <Button component={Link} isLink to="/subscription/semi-annually">6 mesiacov (2/39)</Button>
            <Button component={Link} isLink to="/subscription/annually">12 mesiacov (2/39)</Button>
            <Button component={Link} isLink to="/subscription/gift">chcem darovať predplatné</Button>
            <Button component={Link} isLink to="/subscription/renewal">chcem obnoviť predplatné</Button>
            <Button component={Link} isLink to="/subscription/cancelation">chcem zrušiť predplatné</Button>
          </Flex>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default Subscription
